import React from 'react';
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Navbar from './components/NavBar-component/NavBar';
import Header from './sections/Header/Header';
import Begin from './sections/Begin/Begin';
import Capital from './sections/Capital/Capital';
import HowItWorks from './sections/How-it-works/How';
import Footer from './sections/Footer/Footer';

import LoginView from './components/Login-view/Login-view';

const App = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/">
            <div className="App">
              <Navbar />
              <Header />
              <Capital />      
              <HowItWorks />
              <Begin />
              <Footer />
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
