import React, {Component} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import './Footer.scss';
import Snackbar from '@material-ui/core/Snackbar';

import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";

class footer extends Component {

    componentDidMount() {
        AOS.init({
            duration: 800,
            offset: 200
        });
    }

    state = {
        email: '',
        name: '',
        comment: '',
        openMessageAlert: false,
        messageAlert: ''
      }
    
      handleChange (event) {
        this.setState({
          [event.target.name]: event.target.value
        });
      };
    
      handleMessageAlertOpen(message) {
        this.setState({
          openMessageAlert: true,
          messageAlert: message
        });
      };
    
      handleMessageAlertClose() {
        this.setState({
          openMessageAlert: false
        });
      };
    
      sendEmail() {
        if (this.state.email !== '' && this.state.comment !== '') {
          fetch('https://staging.financeinc.mx/email/v1/contact', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              email: this.state.email,
              comment: this.state.comment
            })
          })
          .then(response => response.json())
          .then(response => {
            if (response.payload) {
              this.handleMessageAlertOpen('Mensaje enviado');
              this.setState({
                name: '',
                email: '',
                comment: ''
              });
            } else {
              this.handleMessageAlertOpen(`Error: ${response.msg}`);
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
      }

    render() {
        return (
            <section className="section-footer" id="contacto">
                <div
                    className="finance-container"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="contact-info">
                        <h2>
                            Llámanos <span>(33) 1721 - 6417</span> o envía un correo electronico
                        </h2>
                        <div id="schedule">
                            <p>Horario de oficina</p>
                            <p>9 - 6 pm Lun - Vier</p>
                        </div>
                        <div id="address">
                            <p>Direccion: <br/>
                                Calle Gral San Martin 425, <br/>
                                Col Americana, Obrera, <br/>
                                44140 Guadalajara, Jal.
                            </p>
                        </div>
                        <div className="logos">
                            <img className="finance-logo" src="/assets/001_Logo_Banner.svg" alt="logo_finance"/>
                            <div className="social">
                                {/* <a href="#">
                                    <img className="social-logo" src="/assets/013_icon_telegram.svg" alt="telegram"/>
                                </a>
                                <a href="#">
                                    <img className="social-logo" src="/assets/014_icon_twitter.svg" alt="twitter"/>
                                </a>
                                <a href="#">
                                    <img className="social-logo" src="/assets/015_icon_whats.svg" alt="whatsapp"/>
                                </a> */}
                                <a href="https://www.facebook.com/financeinc.mx" target="_blank">
                                    <img className="social-logo" src="/assets/016_icon_Face.svg" alt="facebook"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form">
                        <form noValidate autoComplete="off">
                            <TextField
                                id="standard-basic"
                                label="Tu nombre"
                                className="w-100"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange.bind(this)}
                                margin="normal"
                            />
                            <TextField
                                    id="standard-basic"
                                    label="Correo electrónico"
                                    className="w-100"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                            />
                            <TextField
                                    id="standard-basic"
                                    label="Mensaje"
                                    className="w-100"
                                    name="comment"
                                    multiline
                                    rows="4"
                                    value={this.state.comment}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                            />
                            <Button id="btn" variant="contained" color="primary" disableElevation onClick={this.sendEmail.bind(this)}>
                                Enviar
                            </Button>
                        </form>
                    </div>
                </div>
                <div className="copyright">
                    &copy; 2020 Financeinc
                </div>
                <Snackbar
                    open={this.state.openMessageAlert}
                    onClose={this.handleMessageAlertClose.bind(this)}
                    onClick={this.handleMessageAlertClose.bind(this)}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messageAlert}</span>}
                    />
            </section>
        );
    }
}

export default footer;
