import React from 'react';
import './Work-Content.scss';

const workContent = (props) => {
    return (
        <div className="work-info">
            <div className="left-content">
                <h2 className="number">{ props.number }</h2>
            </div>
            <div className="right-content">
                <div className="content-header">
                    <h2>{ props.contentHeader }</h2>
                </div>
                <div className="content-text">
                    <p><span>{ props.span }</span>{ props.contentText }</p>
                </div>
            </div>
        </div>
    );
}

export default workContent;
