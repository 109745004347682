import React from "react";
import clsx from "clsx";
import "./LoginComponent.scss";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(0)
  },
  width: {
      width: "100%"
  }
}));

export default function LoginComponent() {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <section className="finance-container">
      <Card className="Card">
        <CardContent>
        <img className="finance-logo" src="/assets/012_logoFooter.svg" alt="financeinc_logo"/>
          <form noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              label="Tu correo electronico"
              className="w-100"
            />
            
              <FormControl className={clsx(classes.margin, classes.textField, classes.width)}>
              <InputLabel htmlFor="standard-adornment-password">
                  Ingresa tu contraseña
              </InputLabel>
              <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                  <InputAdornment position="end">
                      <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      >
                      {values.showPassword ? (
                          <Visibility />
                      ) : (
                          <VisibilityOff />
                      )}
                      </IconButton>
                  </InputAdornment>
                  }
              />
              </FormControl>
          </form>
        </CardContent>
        <CardActions>
          <Button variant="contained" color="primary" disableElevation>
            Ingresar
          </Button>
        </CardActions>
        <div className="center">
          <p>¿Nuevo Usuario?<span> Registrate</span></p>
          <p>¿Olvidaste tu <span>contraseña</span>?</p>
        </div>
      </Card>
    </section>
  );
}
