import React from 'react';
import './Content-item.scss';

const contentItem = (props) => {
    return (
        <div className="capital-info">
            <div className="left-content">
                <img className="capital-img" src={ props.logoUrl } style={{ maxWidth: '100%', width: props.logoWidth }} alt="logo"/>
            </div>
            <div className="right-content">
                <div className="content-header">
                    <h2>{ props.contentHeader }</h2>
                </div>
                <div className="content-text">
                    <p>{ props.contentText }</p>
                </div>
            </div>
        </div>
    );
}

export default contentItem;
