import React, {Component} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import './How.scss';
import WorkContent from '../../components/Work-Content-item/Work-Content';

class HowItWorks extends Component {

    componentDidMount() {
        AOS.init({
            duration: 800,
            offset: 200
        });
    }  
    
    render() {
        return (
            <section className="section-how-it-works" id="como-trabaja">
                <div
                    className="finance-container"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="finance-header">
                        <h1>Como trabaja</h1>
                        <img src="./assets/001_Logo_Banner.svg" className="finance-header_logo" alt="logo"/>
                    </div>
                    <div className="content">
                        <div className="content-left">
                            <div className="rel">
                                <WorkContent 
                                    number="01"
                                    contentHeader="Aplica en linea" 
                                    contentText="En menos de 10 minutos con detalles básicos sobre su negocio y clientes." 
                                />
                                <hr className="abs abs-1"/>
                            </div>
                            <div className="rel">
                                <WorkContent 
                                    number="02"
                                    contentHeader="Ser aprobado" 
                                    span="Financeinc "
                                    contentText="revisará su solicitud y se comunicará con una decision en tan solo 24 horas." 
                                />
                                <hr className="abs abs-2"/>
                            </div>
                            <div className="rel">
                                <WorkContent 
                                    number="03"
                                    contentHeader="Enviar una factura" 
                                    contentText="Sincronice automáticamente las facturas desde su software de contabilidad o cargue las facturas en su tablero." 
                                />
                                <hr className="abs abs-3"/>
                            </div>
                            <div>
                                <WorkContent 
                                    number="04"
                                    contentHeader="Consigue tu financiamiento" 
                                    contentText="Proporcionamos 85-90% del dinero por adelantado. Obtiene el resto, menos nuestra tarifa, una vez que se paga la factura." 
                                />
                            </div>
                        </div>
                        <div className="content-right">
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HowItWorks;
