import React, { Component } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import './Begin.scss';
import ListComponent from '../../components/List-component/List-component';
import Button from "@material-ui/core/Button";

class Begin extends Component {

    componentDidMount() {
        AOS.init({
            duration: 800,
            offset: 200
        });
    }  

    render() {
        return (
            <section className="section-begin" id="para-empezar">
                <div 
                    className="finance-container"
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="begin-info">
                        <h1>Lo que necesitas para empezar</h1>
                        <div className="content">
                            <div>
                                <h2 className="table-heading">Calificaciones minimas</h2>
                                <div className="table-content">
                                    <ListComponent text="Buen buró" />
                                    <ListComponent text="3+ meses en el negocio" />
                                    <ListComponent text="$ 250,000 en ingresos mensuales" />
                                </div>
                            </div>
                            <div>
                                <h2 className="table-heading">Lo que necesitas para aplicar</h2>
                                <div className="table-content">
                                    <ListComponent text="Alguna informacion sobre su negocio" />
                                    <ListComponent text="Detalles básicos sobre sus facturas" />
                                    <ListComponent text="Conexion bancaria o 3 meses más extractos bancarios recientes" />
                                </div>
                            </div>
                        </div>
                        <div className="apply">
                            <div className="ready">
                                <div>
                                    <h2>Listo para aplicar?</h2>
                                </div>
                                <div className="start-now">
                                    <a href="https://staging.financeinc.mx/login">
                                        <Button id="btn" variant="contained" color="primary" disableElevation>
                                            Comienza ahora
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            <p>Las solicitudes por más de <span>1 millon de pesos </span>pueden requerir informacion adicional.</p>
                        </div>
                    </div>
                    <div className="begin-image">
                    </div>
                </div>
            </section>
        );
    }
}

export default Begin;
