import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import AOS from 'aos';
import 'aos/dist/aos.css'
import './Header.scss';

class Header extends Component {

    componentDidMount() {
        AOS.init({
            duration: 800,
            offset: 200
        });
    }    

    render () {
        return (
            <section className="section-header">
                <div className="finance-container">
                    <div 
                        className="animation"
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        <h1 className="bottom-gap">
                            Convierte tus facturas no pagadas en capital de trabajo
                        </h1>
                        <div className="text">
                            <p>No esperes más, obten un adelanto de tus facturas pendientes</p>
                            <p className="bottom-gap">Comienza ahora uno de nuestros asesores te esta esperando.</p>
                        </div>
                        <a href="https://staging.financeinc.mx/login">
                            <Button id="btn" variant="contained" color="primary" disableElevation>
                                Comienza ahora
                            </Button>
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

export default Header;
