import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import './Capital.scss';
import AOS from 'aos';
import 'aos/dist/aos.css'

import ContentItem from '../../components/Content-item/Content-item';

class Capital extends Component {

    componentDidMount() {
        AOS.init({
            duration: 800,
            offset: 200
        });
    }    

    render() {
        return (
            <section className="section-capital" id="capital">
                <Container maxWidth="lg">
                    <div
                        data-aos="fade-up"
                        data-aos-once="true"
                    >
                        <div className="capital-header">
                            <h1>
                                Accede a más capital con mayor facilidad
                            </h1>
                        </div>
                        <div className="info-container">
                            <ContentItem
                                logoWidth="100px"
                                logoUrl="/assets/004_icono_mayoreslimites.svg"
                                contentHeader="Obtenga mayores límites de crédito" 
                                contentText="Obtenga una línea de crédito basada en la fortaleza de sus clientes. Amedida que crecen sus ventas, tambien puede aumentar su limite de crédito." 
                            />
                            <ContentItem
                                logoWidth="50px"
                                logoUrl="/assets/006_liberatuefectivo.svg"
                                contentHeader="Libera tu efectivo"
                                contentText="A diferencia de un préstamo tradicional, no hay pagos recurrentes cuando su cliente paga en la fecha de vencimiento de la factura."
                            />
                            <ContentItem
                                logoWidth="50px"
                                logoUrl="/assets/005_financiasolo.svg"
                                contentHeader="Financia solo lo que quieres"
                                contentText="Usted decide cuántas y qué facturas enviar. Financia solo lo que necesitas, cuando lo necesitas, sin contratos a largo plazo."
                            />
                            <ContentItem
                                logoWidth="90px"
                                logoUrl="/assets/007_tarifas.svg"
                                contentHeader="Tarifas transparentes"
                                contentText="Sepa exactamente lo que está pagando con nuestras tarifas directas. Cobramos una tarifa semanal simple cuando se paga la factura."
                            />
                        </div>
                    </div>
                </Container>
            </section>
        );
    }
}

export default Capital;
