import React from 'react';
import './List-component.scss';

const ListComponent = (props) => {
    return (
        <div className="list-container">
            <div className="icon">
                <img src="/assets/011_icono_check.svg" alt="icon"/>
            </div>
            <div className="text">
                <p>{props.text}</p>
            </div>
        </div>
    );
}

export default ListComponent;
