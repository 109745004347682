import React, { Component } from 'react';
import './NavBar.scss';

class Navbar extends Component{

    closeNav = () => {
        document.getElementById("navi-toggle").checked = false;
    }

    render () {
        return (
            <div className="navigation">
                <img className="navigation__logo" src="/assets/001_Logo_Banner.svg" alt="logo"/>
                <div className="navigation__info">
                    <div>
                        <img src="/assets/002_icono_telefono.svg" alt="phone" className="info-icon"/>
                    </div>
                    <div>
                        <p>(33) 1721 - 6417</p>
                    </div>
                </div>
                <input type="checkbox" className="navigation__checkbox" id="navi-toggle" />
    
                <label htmlFor="navi-toggle" className="navigation__button">
                    <span className="navigation__icon">
                        &nbsp;
                    </span>
                </label>
    
                <div className="navigation__background">
                    &nbsp;
                </div>
    
                <nav className="navigation__nav">
                    <img className="navigation__logo" src="/assets/001_Logo_Banner.svg" alt="logo"/>
                    <div className="navigation__info">
                        <div>
                            <img src="/assets/002_icono_telefono.svg" alt="phone" className="info-icon"/>
                        </div>
                        <div>
                            <p>(33) 1721 - 6417</p>
                        </div>
                    </div>
                    <ul className="navigation__list">
                        <li className="navigation__item">
                            <a href="#capital" className="navigation__link" onClick={ this.closeNav }>Accede a mas capital</a>
                        </li>
                        <li className="navigation__item">
                            <a href="#como-trabaja" className="navigation__link" onClick={ this.closeNav }>Como trabaja</a>
                        </li>
                        <li className="navigation__item">
                            <a href="#para-empezar" className="navigation__link" onClick={ this.closeNav }>Lo que necesitas para empezar</a>
                        </li>
                        <li className="navigation__item">
                            <a href="#contacto" className="navigation__link" onClick={ this.closeNav }>Contacto</a>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Navbar;
