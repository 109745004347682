import React from 'react';
import './Login-view.scss'
import LoginComponent from '../Login-component/LoginComponent';

const LoginView = () => {
    return (
        <main>
            <div className="flex-view">
                <div className="flex-view left-side">
                    <img className="finance-logo" src="/assets/012_logoFooter.svg" alt="financeinc_logo"/>
                    <div className="welcome-container">
                        <h1>Bienvenido</h1>
                        <div className="welcome-text">
                            <p>Para continuar,</p>
                            <p>ingresa tu informacion de inicio de sesión</p>
                        </div>
                    </div>
                    <div className="welcome-footer">
                        &copy; 2020 Financeinc
                    </div>
                </div>
                <div className="flex-view right-side">
                    <div className="welcome-container">
                        <h1>Bienvenido</h1>
                        <div className="welcome-text">
                            <p>Para continuar,</p>
                            <p>ingresa tu informacion de inicio de sesión</p>
                        </div>
                    </div>
                    <div className="login-container">
                        <LoginComponent />
                    </div>
                    <div className="welcome-footer">
                        &copy; 2020 Financeinc
                    </div>
                </div>
            </div>
        </main>
    );
}

export default LoginView;